import React from "react";
import { ReactTyped } from "react-typed";
import hero from "../../images/home.svg";
import { FaCopy } from "react-icons/fa";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy!");
      });
  };

  const callUs = () => {
    const message = "0791004783";
    toast(<ToastWithCopy message={message} />, { duration: 10000 });
    window.location.href = "tel:0791004783";
  };

  const ToastWithCopy = ({ message }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ display: "flex" }}>
        Our Number:{" "}
        <span style={{ color: "green", marginLeft: "4px" }}>{message}</span>
      </span>
      <FaCopy
        style={{ marginLeft: "8px", cursor: "pointer", color: "green" }}
        onClick={() => copyToClipboard(message)}
      />
    </div>
  );

  return (
    <>
      {" "}
      <section class="hero" id="home">
        <div class="container">
          <div class="hero-content">
            <p class="hero-subtitle" style={{ display: "flex", gap: "3px" }}>
              We {"  "}
              <ReactTyped
                strings={[
                  " are website developers.",
                  " create ecommerce websites.",
                  " bring your ideas to life.",
                  " design user-friendly experiences.",
                  " build stunning websites.",
                  " make your brand shine online.",
                  " craft seamless digital solutions.",
                  " turn visions into reality.",
                  " deliver high-quality results.",
                  " empower businesses with technology.",
                ]}
                typeSpeed={80}
                backSpeed={50}
                backDelay={1000}
                startDelay={500}
                loop={true}
                showCursor={false}
              />
            </p>

            <h2 class="h2 hero-title">We Create Websites that Users Love.</h2>

            <p class="hero-text">
              We pour our hearts into every project, working tirelessly to
              deliver exceptional web solutions tailored to elevate your
              business. From immersive WebApps to captivating websites, we're
              here to turn your vision into reality.
            </p>
            <div style={{ display: "flex" }}>
              <button
                onClick={() => callUs()}
                class="btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 5px",
                  borderRadius: "0 20px 0  10px",
                }}
              >
                <ion-icon
                  name="headset-outline"
                  style={{ marginRight: "3px", fontSize: "20px" }}
                ></ion-icon>{" "}
                Call Us
              </button>
              <button
                onClick={() => navigate("/projects")}
                class="btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 5px",
                  borderRadius: "20px 0 10px 0",
                }}
              >
                <ion-icon
                  name="folder-outline"
                  style={{ marginRight: "3px", fontSize: "20px" }}
                ></ion-icon>{" "}
                See Projects
              </button>
            </div>
          </div>

          <figure class="hero-banner" style={{ position: "relative" }}>
            <img
              src={hero}
              width="694"
              height="529"
              loading="lazy"
              alt="hero-banner"
              class="w-100"
            />
            <a
              href="https://www.google.com/maps/place/Three+Dolts/@0,0,12z/data=!4m15!1m8!3m7!1s0x897dc9a94424b32b:0xb450beecce7bf033!2sThree+Dolts!8m2!3d-1.1536634!4d37.0132762!10e5!16s%2Fg%2F11vyp75_wj!3m5!1s0x897dc9a94424b32b:0xb450beecce7bf033!8m2!3d-1.1536634!4d37.0132762!16s%2Fg%2F11vyp75_wj?hl=sw&entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D"
              target="__blank"
              style={{
                alignItems: "center",
                padding: "6px 9px",
                borderRadius: "8px",
                fontSize: "13px",
                color: "#2a2a2a",
                margin: "10px",
                width: "fit-content",
                cursor: "pointer",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(5px)",
                position: "absolute",
                top: "5%",
                left: "-5%",
              }}
              className="banner-animation"
            >
              <div
                style={{ display: "flex", textAlign: "center", color: "#ffff" }}
              >
                <div style={{ fontSize: "16px" }}>Clients reviews from</div>
                <img
                  src="https://res.cloudinary.com/dxxhtz2dw/image/upload/v1731573572/three-dolts-website-images/kjuktyiskzyl3ko0wxji.png"
                  alt="google"
                  style={{
                    width: "45px",
                    height: "15px",
                    margin: "7px 0 0 3px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "20px",
                    color: "#FFA000",
                    marginRight: "5px",
                  }}
                >
                  ★
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#FFA000",
                    marginRight: "5px",
                  }}
                >
                  ★
                </span>{" "}
                <span
                  style={{
                    fontSize: "20px",
                    color: "#FFA000",
                    marginRight: "5px",
                  }}
                >
                  ★
                </span>
                <span
                  style={{
                    fontSize: "20px",
                    color: "#FFA000",
                    marginRight: "5px",
                  }}
                >
                  ★
                </span>{" "}
                <span
                  style={{
                    fontSize: "20px",
                    color: "#FFA000",
                    marginRight: "5px",
                  }}
                >
                  ★
                </span>
              </div>
            </a>
          </figure>
        </div>
      </section>
    </>
  );
};

export default Hero;
